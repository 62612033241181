import LoopSlider from "./LoopSlider";

let homeBannerSlider = new Swiper(".home-banner-slider .swiper", {
	slidesPerView: 1,
	spaceBetween: 12,
	loop: true,
	autoplay: {
		delay: 4000,
	},
	pagination: {
		el: ".home-banner-slider .swiper-pagination",
		clickable: true,
	},
});
let feedbackSlider = new Swiper(".feedback-slider .swiper", {
	slidesPerView: 1.5,
	spaceBetween: 16,
	autoplay: {
		delay: 4000,
	},
	navigation: {
		prevEl: ".feedback-slider .swiper-prev",
		nextEl: ".feedback-slider .swiper-next",
	},
	breakpoints: {
		576: {
			slidesPerView: 2.5,
		},
		768: {
			slidesPerView: 2.5,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 32,
		},
	},
});

let homeSpecialtySlider = new Swiper(".home-specialty-slider .swiper", {
	slidesPerView: 1.5,
	spaceBetween: 16,
	loop: true,
	pagination: {
		el: ".home-specialty-slider .swiper-pagination",
		type: "progressbar",
	},
	breakpoints: {
		576: {
			slidesPerView: 2.5,
		},
		768: {
			slidesPerView: 3.5,
		},
		1280: {
			slidesPerView: 3.5,
		},
		1440: {
			slidesPerView: 4.5,
		},
	},
});

let packageSlider = new Swiper(".package-slider .swiper", {
	slidesPerView: 2,
	spaceBetween: 16,
	loop: true,
	navigation: {
		prevEl: ".package-slider .swiper-prev",
		nextEl: ".package-slider .swiper-next",
	},
	autoplay: {
		delay: 4000,
	},
	breakpoints: {
		576: {
			slidesPerView: 2.5,
		},
		768: {
			slidesPerView: 3.5,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 20,
		},
		1440: {
			slidesPerView: 5,
			spaceBetween: 20,
		},
	},
	on: {
		afterInit: function () {
			setTimeout(() => {
				$(".package-item").each(function (index, element) {
					$(element)
						.find(".package-item-content")
						.css({
							"--content-height":
								$(element)
									.find(".package-item-content-inner")
									.outerHeight() + "px",
						});
				});
			}, 300);
		},
		resize: function () {
			setTimeout(() => {
				$(".package-item").each(function (index, element) {
					$(element)
						.find(".package-item-content")
						.css({
							"--content-height":
								$(element)
									.find(".package-item-content-inner")
									.outerHeight() + "px",
						});
				});
			}, 300);
		},
	},
});

homeSpecialtySlider.on("slideChange", function (swiper) {
	$(".current-slider-index").text(
		(swiper.realIndex + 1 < 10 ? "0" : "") + (swiper.realIndex + 1)
	);
});

const videoSlider = new LoopSlider(
	".video-slider",
	{
		576: {
			spaceBetween: 16,
			slidesPerView: 1.5,
		},
		768: {
			spaceBetween: 16,
			slidesPerView: 2.5,
		},
		1024: {
			spaceBetween: 32,
			slidesPerView: 2,
		},
	},
	{
		slidesPerView: 1.25,
	}
);

const newsSlider = new LoopSlider(
	".news-slider",
	{
		576: {
			spaceBetween: 16,
			slidesPerView: 2.5,
		},
		768: {
			spaceBetween: 16,
			slidesPerView: 3.5,
		},
		1024: {
			spaceBetween: 20,
			slidesPerView: 4,
		},
	},
	{
		slidesPerView: 2,
	}
);

const doctorSlider = new LoopSlider(
	".doctor-slider",
	{
		576: {
			spaceBetween: 16,
			slidesPerView: 2.5,
		},
		768: {
			spaceBetween: 16,
			slidesPerView: 3.5,
		},
		1024: {
			spaceBetween: 20,
			slidesPerView: 4,
		},
	},
	{
		slidesPerView: 2,
	}
);

const memberSlider = new LoopSlider(
	".member-slider",
	{
		576: {
			spaceBetween: 16,
			slidesPerView: 1.5,
		},
		768: {
			spaceBetween: 16,
			slidesPerView: 2.5,
		},
		1024: {
			spaceBetween: 20,
			slidesPerView: 3,
		},
	},
	{
		slidesPerView: 2,
	}
);
