import { Fancybox } from "@fancyapps/ui";
import "./modules/Header";
import "./modules/Slider";
import "./modules/Accordion";
import "./modules/BackToTop";
import "./modules/SvgInject";
import "./modules/ServiceDetail";
import "./modules/Home";
import "./modules/Booking";
import "./modules/FormBtn.js";

var lazyLoadInstance = new LazyLoad({
	// Your custom settings go here
});

window.lazyLoadInstance = lazyLoadInstance;
window.Fancybox = Fancybox;

Fancybox.bind("[data-fancybox]", {
	// Your custom options
});

$(function () {
	if ($(".profile-container").length > 0) {
		$("body").addClass("no-banner-page");
	}
	// $("#triggerPopup").click(function () {
	// 	Fancybox.show([{ src: "#bookingPopup", type: "inline" }]);
	// });
});
