const serviceDetailSlider = () => {
	const serviceDetailThumbnailSlider = new Swiper(
		".service-thumbnail-slider .swiper",
		{
			spaceBetween: 12,
			slidesPerView: 2.5,
			observer: true,
			observeParents: true,
			slideToClickedSlide: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			breakpoints: {
				768: {
					spaceBetween: 20,
					slidesPerView: 2.5,
				},
				1024: {
					spaceBetween: 20,
					slidesPerView: 3,
				},
			},
		}
	);
	const serviceDetailImageSlider = new Swiper(
		".service-image-slider .swiper",
		{
			effect: "fade",
			observer: true,
			observeParents: true,
			thumbs: {
				swiper: serviceDetailThumbnailSlider,
			},
			on: {
				slideChange: function () {
					let activeIndex = this.activeIndex + 1;

					let nextSlide = $(
						`.service-thumbnail-slider .swiper-slide:nth-child(${
							activeIndex + 1
						})`
					);
					let prevSlide = $(
						`.service-thumbnail-slider .swiper-slide:nth-child(${
							activeIndex - 1
						})`
					);

					if (
						nextSlide &&
						!nextSlide.hasClass("swiper-slide-visible")
					) {
						this.thumbs.swiper.slideNext();
					} else if (
						prevSlide &&
						!prevSlide.hasClass("swiper-slide-visible")
					) {
						this.thumbs.swiper.slidePrev();
					}
				},
			},
		}
	);
};

serviceDetailSlider();

$(".service-filter").each(function (index, element) {
	let select = $(element).find("select");
	let placeholder = $(element).find(".service-filter-placeholder");
	$(select).customSelect({
		placeholder: placeholder,
	});
});

$(".parent-filter-select").change(function () {
	// Get the value of the selected option
	var selectedValue = $(this).val();

	// Hide all service-filter divs
	$(".service-filter-children").hide();

	// Show the div that matches the selected option's value
	$(`.service-filter-children[id="${selectedValue}"]`).show();
	$(`.service-filter-children[id="${selectedValue}"]`)
		.find("select")
		.trigger("change");
});

$(".service-filter-ajax select").on("change", function () {
	// Get the value of the selected option
	var selectedValue = $(this).val();
	$.ajax({
		url: selectedValue,
		type: "GET",
		success: function (response) {
			var targetDiv = $(".ajax-service-response-wrapper");
			targetDiv.html($(response).find(".ajax-service-response"));
		},
		error: function (xhr, status, error) {
			// Handle errors here
			console.error("Error occurred: " + error);
		},
	});
});
