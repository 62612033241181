function appendFormBtn() {
	try {
		var observerForm = new MutationObserver(function (mutations) {
			mutations.forEach(function (mutation) {
				if (mutation.type === "childList") {
					$(".wrap-form").each(function () {
						let formRow = $(this).find(".frm-captcha");
						let submitContainer = $(this).find(".frm-btnwrap");
						$(formRow).append($(submitContainer));
					});
				}
			});
		});

		observerForm.observe(
			document.querySelector(".form-container .ModuleContent > div"),
			{
				childList: true,
			}
		);

		$(".wrap-form").each(function () {
			let formRow = $(this).find(".frm-captcha");
			let submitContainer = $(this).find(".frm-btnwrap");
			$(formRow).append($(submitContainer));
		});
	} catch (error) {}
}

appendFormBtn()