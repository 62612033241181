var $globalHeader = $(".global-header");

$(function () {
	if (
		($(".banner-section").length > 0) &
		($(".banner-section .banner-item").length === 0)
	) {
		$("body").addClass("no-banner-page");
	}
	let headerHeight = $globalHeader.outerHeight();
	$("body").css({
		"--header-height": headerHeight + "px",
	});
	window.headerHeight = headerHeight;
	let headerPositionCss = $globalHeader.css("position");

	if (headerPositionCss === "relative") {
		$globalHeader.sticky({
			top: 0,
			zIndex: 100,
		});
	}
});

var lastScrollTop = 0; // Variable to store the last scroll position

$(window).on("scroll", function () {
	var scrollTop = window.pageYOffset || $(document).scrollTop(); // Current scroll position

	// Check if the current scroll position is greater than last scroll position (scrolling down)
	// and if it's greater than 0 (not at the top).
	if (scrollTop > lastScrollTop && scrollTop > 0) {
		$globalHeader.addClass("scrolling");
	} else {
		// Otherwise, remove the class (scrolling up or at the top).
		$globalHeader.removeClass("scrolling");
	}

	lastScrollTop = scrollTop; // Update the last scroll position
});

$globalHeader.on("sticky-end", function () {
	$("#sticky-wrapper").css({
		height: headerHeight,
	});
});

try {
	const accountMapping = new MappingListener({
		selector: ".account-wrapper",
		mobileWrapper: ".menu-mobile-body",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-right-top",
		desktopMethod: "appendTo",
		breakpoint: 1025,
	}).watch();

	const menuMapping = new MappingListener({
		selector: ".menu-wrapper",
		mobileWrapper: ".menu-mobile-body",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-right-bottom",
		desktopMethod: "prependTo",
		breakpoint: 1025,
	}).watch();

	const headerBtnMapping = new MappingListener({
		selector: ".header-btn-group-wrapper",
		mobileWrapper: ".fixed-cta-mobile",
		mobileMethod: "appendTo",
		desktopWrapper: ".menu-toggle",
		desktopMethod: "insertAfter",
		breakpoint: 1025,
	}).watch();
} catch (error) {}

$(".mega-menu-lv-1 li").each(function (index, element) {
	let _this = $(element);
	let menuId = _this.attr("data-menu");
	let timeout;
	if ($(window).width() > 1024.98) {
		_this.hover(
			function () {
				clearTimeout(timeout);
				$(`.mega-menu-lv-2`).hide();
				$(`.mega-menu-lv-2[data-menu="${menuId}"]`).show();
			},
			function () {
				timeout = setTimeout(function () {
					$(`.mega-menu-lv-2[data-menu="${menuId}"]`).hide();
				}, 200);
			}
		);

		$(`.mega-menu-lv-2[data-menu="${menuId}"]`).hover(
			function () {
				clearTimeout(timeout);
			},
			function () {
				$(this).hide();
			}
		);
	} else {
		_this.find(".toggle-mega").on("click", function () {
			$(`.mega-menu-lv-2[data-menu="${menuId}"]`).addClass("show");
		});
	}
});

$(".close-menu-mobile, .menu-mobile-backdrop").on("click", function () {
	$(".menu-mobile").removeClass("show");
	$("body").removeClass("overflow-hidden");
	$(".menu-mobile-backdrop").fadeOut();
});

$(".toggle-sub-menu").each(function (index, el) {
	$(el).on("click", function () {
		$(el).next().slideToggle();
	});
});

$(".toggle-mega").each(function (index, el) {
	$(el).on("click", function () {
		$(el).next().toggleClass("show");
	});
});

$(".toggle-mega-sub-menu").each(function (index, el) {
	$(el).on("click", function () {
		var targetMenu = $(el).parent().next();

		// Slide up all the other .mega-menu-lv2 elements
		$(".mega-menu-lv2").not(targetMenu).slideUp();
		$(".toggle-mega-sub-menu").not($(el)).removeClass("fa-rotate-180");

		// Toggle the clicked element
		targetMenu.slideToggle();
		$(el).toggleClass("fa-rotate-180");
	});
});

$(".close-mega").each(function (index, el) {
	$(el).on("click", function () {
		$(el).parent().removeClass("show");
	});
});

$(".menu-toggle").on("click", function () {
	$(".menu-mobile").addClass("show");
	$("body").addClass("overflow-hidden");
	$(".menu-mobile-backdrop").fadeIn();
});
